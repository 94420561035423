import styled from 'styled-components'
import media from 'styled-media-query'

export const Heading = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  jusitify-content: center;
  position: relative;
  z-index: 0;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  display: block;
  font-weight: bold;
  text-align: center;
  font-size: ${({ size }) => `${size || 60}px`};
  line-height: ${({ size }) => `${(size || 60) / 1.5}px`};
  letter-spacing: -5px;
  margin: 0;
  position: relative;
  z-index: 1;

  ${media.lessThan('medium')`
    font-size: ${({ size }) => `${size * 0.7 || 60 * 0.7}px`};
    line-height: ${({ size }) => `${(size * 0.7 || 60 * 0.7) / 1.5}px`};
    letter-spacing: -3px;
  `}
`

export const Subtitle = styled.strong`
  font-family: 'Striverx';
  font-size: ${({ size }) => `${size || 91}px`};
  line-height: ${({ size }) => `${(size || 91) / 1.8}px`};
  font-weight: 100;
  display: block;
  color: white;
  position: relative;
  z-index: 1;

  ${media.lessThan('medium')`
    font-size: ${({ size }) => `${size * 0.8 || 91 * 0.8}px`};
    line-height: ${({ size }) => `${(size * 0.8 || 91 * 0.8) / 1.8}px`};
  `}
`

export const Kanjis = styled.img`
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;

  ${media.lessThan('medium')`
    height: 80vw;
  `}
`
