import React, { useState } from 'react'
import { getAllBanners } from '../../services'

let BannersContext
const { Consumer, Provider } = (BannersContext = React.createContext())

const BannersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [banners, setBanners] = useState([])

  const getAll = async () => {
    await setLoading(true)
    try {
      const allBanners = await getAllBanners()
      await setBanners(allBanners.data.data)
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
    }
  }

  return <Provider value={{ loading, banners, getAllBanners: getAll }}>{children}</Provider>
}

export { BannersProvider, Consumer as BannersConsumer, BannersContext }
