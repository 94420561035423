import { createGlobalStyle } from 'styled-components'
import WebFont from 'webfontloader'
import './../typography.css'

WebFont.load({
  google: {
    families: ['Oxygen:300,400,700'],
  },
  custom: {
    families: ['Striverx'],
    urls: ['../typography.css'],
  },
})

export const theme = {
  font: {
    primary: 'Oxygen',
    secondary: 'Striverx',
    kanji: 'Genkaimincho',
  },
  color: {
    primary: '#CC1428',
    darkGrey: '#1A1818',
  },
}

export const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  html {
    overflow-x: hidden;
    font-size: 18px;
    color: white;
    line-height: calc(18px * 1.2);
    background-color: ${theme.color.darkGrey};
    margin: 0;
    padding: 0;
    font-family: ${theme.font.primary};
    color: white;
  }
`
