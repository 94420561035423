import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
`

const Taiyo = styled.img`
  width: 70px;
  animation: ${pulse} 800ms ease infinite;
`

const Loading = () => {
  return <Taiyo src={require('../../static/images/taiyo-logo.svg')} />
}

export default Loading
