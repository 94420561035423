import React from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-input-mask'
import media from 'styled-media-query'

const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  width: ${({ size }) => size};
  position: relative;
  margin-bottom: 15px;

  ${media.lessThan('medium')`
    width: 100%;
  `}

  :after {
    content: '';
    width: 7px;
    height: 40px;
    position: absolute;
    left: -3px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Input = styled(MaskedInput)`
  width: 100%;
  padding: 22px 30px;
  font-size: 18px;
  color: white;
  border: 1px solid ${({ error, theme }) => (error ? theme.color.primary : 'white')};
  background: transparent;
  transition: all 200ms ease;

  :focus,
  :active {
    outline: none;
  }

  :placeholder {
    color: white;
    opacity: 1;
  }
`

const Component = ({ size, ...props }) => (
  <Fieldset size={size}>
    <Input {...props} autoComplete="off" />
  </Fieldset>
)

export default Component
