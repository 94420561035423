import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { Heading, Title, Subtitle, Kanjis } from '../Layout/Headings'

const Section = styled.section`
  width: 100%;

  ${Heading} {
    margin: 250px 0 150px 0;

    ${media.lessThan('medium')`
      margin: 150px 0;
    `}
  }
`

const MapHolder = styled.div`
  width: 100%;
  height: 80vh;
  background: grey;
  position: relative;
  overflow: hidden;
`

const Address = styled.div`
  width: 750px;
  height: 165px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: absolute;
  bottom: 50px;
  right: 0;
  left: 0;
  margin: 0 auto;
  background-image: url(${require('../../static/images/bg_endereco.png')});

  ${media.lessThan('medium')`
    width: 95%;
    background-size: 100% 100%;
    text-align: center;
    padding: 0 50px;
  `}
`

const Location = props => (
  <Section ref={props.useRef}>
    <Heading>
      <Title>COMO</Title>
      <Subtitle>Chegar</Subtitle>
      <Kanjis src={require('../../static/images/kanjis_como_chegar.png')} />
    </Heading>

    <MapHolder>
      <Map google={props.google} zoom={17} initialCenter={{ lat: -29.15532, lng: -51.19994 }}>
        <Marker
          name={'Current location'}
          icon={{
            url: require('../../static/images/marcador_taiyo.png'),
          }}
        />
      </Map>
      <Address>Rua Matheo Gianella 1417, 95032-500 Caxias do Sul/RS</Address>
    </MapHolder>
  </Section>
)

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDFslb4nljpqjC4RjChboAMEr_t-zlG7c4',
})(Location)
