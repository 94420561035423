import React, { useState } from 'react'
import { getAllCategories } from '../../services'

let PlatesContext
const { Consumer, Provider } = (PlatesContext = React.createContext())

const PlatesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(undefined)

  const getCategories = async () => {
    await setLoading(true)
    try {
      const allCategories = await getAllCategories()
      await setCategories(allCategories.data.data)
      await setCategory(allCategories.data.data[0])
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
    }
  }

  return (
    <Provider value={{ loading, category, setCategory, categories, getCategories: getCategories }}>
      {children}
    </Provider>
  )
}

export { PlatesProvider, Consumer as PlatesConsumer, PlatesContext }
