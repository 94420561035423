import styled from 'styled-components'
import media from 'styled-media-query'

export const Text = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: white;
  max-width: 580px;
  text-align: center;
  position: relative;
  z-index: 2;

  ${media.lessThan('medium')`
    padding: 0 20px;
  `}
`
