import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import Hamburger from './Hamburger'
import Menu from './Menu'

const Wrapper = styled.header`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 140px;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  max-width: 1980px;

  ${media.lessThan('medium')`
    padding: 0 20px;
    padding-top: 20px;
  `}
`

const Taiyo = styled.img`
  position: relative;
  z-index: 333;
`

const Header = ({ isMenuOpen, setMenuOpen }) => {
  return (
    <Wrapper>
      <Taiyo src={require('../../static/images/taiyo.svg')} />
      <Hamburger isOpen={isMenuOpen} onClick={e => setMenuOpen(!isMenuOpen)} />
      <Menu isOpen={isMenuOpen} />
    </Wrapper>
  )
}

export default Header
