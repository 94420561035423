import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Title, Subtitle, Heading } from '../Layout/Headings'
import { Text } from '../Layout/Text'

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-image: url(${require('../../static/images/atendimento.png')});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 815px;

  ${media.lessThan('medium')`
    height: auto;
    padding: 60px 0;
    background: ${({ theme }) => theme.color.primary};
  `}

  ${Heading} {
    ${Title} {
      color: ${({ theme }) => theme.color.darkGrey};
    }
  }
`

const Wrapper = styled.div`
  width: 95%;
  max-width: 2000px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  ${Heading} {
    width: auto;
    margin-right: auto;

    ${media.lessThan('medium')`
      margin-left: auto;
    `}
  }
`

const Hours = styled.div`
  margin-right: 25%;

  ${media.lessThan('medium')`
    margin: 0 auto;
    margin-top: 20px;
  `}

  ${Text} {
    font-size: 26px;
    line-height: 50px;
    margin: 0;
  }
`

const WorkingHour = props => (
  <Section ref={props.useRef}>
    <Wrapper>
      <Heading>
        <Title size={91}>NOSSO</Title>
        <Subtitle size={91}>Atendimento</Subtitle>
      </Heading>

      <Hours>
        <Text>Segunda à Sábado</Text>
        <Text>Das 19h às 23h</Text>
      </Hours>
    </Wrapper>
  </Section>
)

export default WorkingHour
