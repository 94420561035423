import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { Heading, Title, Subtitle, Kanjis } from '../Layout/Headings'
import { Text } from '../Layout/Text'
import Input from './Input'
import Textarea from './Textarea'
import Marker from '../../static/images/enviar.svg'
import { sendEmail } from '../../services/contact'

const Section = styled.section`
  padding: 200px 0 100px 0;
  position: relative;
`

const FormWrapper = styled.div`
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0;
  margin: 0 auto;

  ${Text} {
    width: 100%;
    text-align: center;
    font-size: 29px;
    line-height: 29px;
    font-weight: bold;
    max-width: 100%;
    margin-top: 100px;
    margin-bottom: 70px;
  }
`

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const MarkerImage = styled.img`
  position: absolute;
  top: calc(100% + 5px);
  left: -35px;
  transition: all 200ms ease;
`

const Submit = styled.button`
  border: 0;
  padding: 0;
  background: transparent;
  font-size: 53px;
  line-height: 53px;
  color: white;
  font-family: ${({ theme }) => theme.font.secondary};
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
  cursor: pointer;

  :hover {
    ${MarkerImage} {
      left: -30px;
    }
  }

  :focus,
  :active {
    outline: none;
  }
`

const LinkToZbra = styled.a`
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  transition: all 200ms ease;

  :hover {
    opacity: 1;
  }
`

const ZbraLogo = styled.img`
  display: flex;
`

const Contact = props => {
  const [submitting, setSubmitting] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  })

  const onSubmit = async values => {
    setSubmitting(true)
    await sendEmail(values)
    setInitialValues({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    })
    setSubmitting(false)
  }

  const required = value => (value ? undefined : 'Campo obrigatório')

  const mustBeEmail = value => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(value) ? undefined : 'Digite um e-mail válido'
  }

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

  return (
    <Section ref={props.useRef}>
      <Heading>
        <Title>ENTRE EM</Title>
        <Subtitle>Contato</Subtitle>
        <Kanjis src={require('../../static/images/kanjis_contato.png')} />
      </Heading>
      <FormWrapper>
        <Text>FAÇA SUA RESERVA: (54) 3538-0900</Text>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validateOnBlur
          subscription={{ pristine: true, invalid: true }}
          render={({ handleSubmit }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Field name="name" validate={required}>
                {({ input, meta }) => (
                  <Input
                    {...input}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    size={'100%'}
                    placeholder="Nome"
                    disabled={submitting}
                  />
                )}
              </Field>
              <Field name="email" validate={composeValidators(required, mustBeEmail)}>
                {({ input, meta }) => (
                  <Input
                    {...input}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    size={'calc(50% - 7.5px)'}
                    placeholder="E-mail"
                    disabled={submitting}
                  />
                )}
              </Field>
              <Field name="phone" validate={required}>
                {({ input, meta }) => (
                  <Input
                    {...input}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    size={'calc(50% - 7.5px)'}
                    mask="(99) 99999 9999"
                    placeholder="Telefone"
                    disabled={submitting}
                  />
                )}
              </Field>
              <Field name="subject" validate={required}>
                {({ input, meta }) => (
                  <Input
                    {...input}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    size={'100%'}
                    placeholder="Assunto"
                    disabled={submitting}
                  />
                )}
              </Field>
              <Field name="message" validate={required}>
                {({ input, meta }) => (
                  <Textarea
                    {...input}
                    error={meta.touched && meta.error ? meta.error : undefined}
                    size={'100%'}
                    placeholder="Mensagem"
                    disabled={submitting}
                  />
                )}
              </Field>
              <Submit disabled={submitting}>
                {submitting ? 'Enviando...' : 'Enviar mensagem'}
                <MarkerImage src={Marker} />
              </Submit>
            </StyledForm>
          )}
        />
      </FormWrapper>

      <LinkToZbra href="http://agenciazbra.com" target="_blank">
        <ZbraLogo src={require('../../static/images/zbra.svg')} title="Agência Zbra" />
      </LinkToZbra>
    </Section>
  )
}

export default Contact
