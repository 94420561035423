import React, { useEffect, useState, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import media from 'styled-media-query'
import { BannersContext, BannersProvider } from './context'

import { Heading, Title, Subtitle, Kanjis } from '../Layout/Headings'
import { Text } from '../Layout/Text'
import Bottom from '../Layout/Bottom'
import Loading from '../Loading'

const Section = styled.section`
  width: 100%;
  height: 120vh;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Banners = styled.ul`
  width: 100%;
  height: 120vh;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Banner = styled.li`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: ${({ isCurrent }) => (isCurrent ? 1 : 0)};

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`

const Bullets = styled.ul`
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 6;
  right: 20px;
  margin: auto;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-flow: column;
`

const Bullet = styled.li`
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
  margin: 18px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: background-color 200ms ease;
  background-color: ${({ isCurrent }) => (isCurrent ? 'white' : 'transparent')};

  :hover {
    background-color: white;
    cursor: pointer;
  }
`

const Description = styled(Text)`
  margin-top: 98px;
`

const Inner = styled.div``

const Image = styled.img`
  max-height: 120vh;
`

const MainImage = styled(Parallax)`
  position: absolute;
  top: 10%;
  bottom: 0;
  right: 0;
  left: 70%;
  margin: auto;
  z-index: 2;

  ${media.lessThan('medium')`
    display: none;
  `}
`

const AuxImage = styled(Parallax)`
  position: absolute;
  top: 20%;
  bottom: 0;
  right: 0;
  left: 70%;
  margin: auto;
  z-index: 1;
  ${media.lessThan('medium')`
    display: none;
  `}
`

const LoadingScreen = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  align-item: center;
  justify-content: center;
`

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: ${zoomIn} 2200s linear infinite;
`

const Home = props => {
  const { loading, banners, getAllBanners } = useContext(BannersContext)
  const [currentBanner, setCurrentBanner] = useState(0)
  const isCurrent = index => index === currentBanner

  useEffect(() => {
    getAllBanners()
  }, [])

  if (!banners || loading)
    return (
      <LoadingScreen>
        <Loading />
      </LoadingScreen>
    )

  return (
    <Section ref={props.useRef}>
      <Banners current={1}>
        {banners.map((banner, index) => (
          <Banner key={banner.id} id={index} isCurrent={isCurrent(index)}>
            <Inner>
              <BackgroundWrapper>
                <Background src={banner.background_image} />
              </BackgroundWrapper>
              <Heading>
                <Kanjis src={require(`../../static/images/kanjis_banner.png`)} />
                <Title size={91}>{banner.title}</Title>
                <Subtitle size={136}>{banner.subtitle}</Subtitle>
              </Heading>

              <Description>{banner.description}</Description>
            </Inner>

            <MainImage offsetYMax={15} offsetYMin={-15} slowerScrollRate tag="figure">
              <Image src={banner.primary_image} />
            </MainImage>
            <AuxImage offsetYMax={8} offsetYMin={-8} slowerScrollRate tag="figure">
              <Image src={banner.secondary_image} />
            </AuxImage>
          </Banner>
        ))}
      </Banners>

      <Bullets>
        {banners.map((banner, index) => (
          <Bullet
            key={banner.id}
            isCurrent={isCurrent(index)}
            onClick={e => setCurrentBanner(index)}
          />
        ))}
      </Bullets>

      <Bottom src={require(`../../static/images/corte_banner.png`)} />
    </Section>
  )
}

export default ({ ...props }) => (
  <BannersProvider>
    <Home {...props} />
  </BannersProvider>
)
