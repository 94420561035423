import React from 'react'
import styled from 'styled-components'

const Fieldset = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  font-family: ${({ theme }) => theme.font.primary};

  :after {
    content: '';
    width: 7px;
    height: 40px;
    position: absolute;
    left: -3px;
    top: 15px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Input = styled.textarea`
  width: 100%;
  height: 250px;
  padding: 22px 30px;
  font-size: 18px;
  line-height: 22px;
  color: white;
  border: 1px solid ${({ error, theme }) => (error ? theme.color.primary : 'white')};
  background: transparent;
  resize: none;
  font-family: ${({ theme }) => theme.font.primary};

  :focus,
  :active {
    outline: none;
  }

  :placeholder {
    color: white;
    opacity: 1;
  }
`

const Component = props => (
  <Fieldset>
    <Input {...props} autoComplete="off" />
  </Fieldset>
)

export default Component
