import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

console.log(process.env)

export * from './banners'
export * from './plates'
export * from './contact'
