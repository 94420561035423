import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Heading, Title, Subtitle } from '../Layout/Headings'
import { Text } from '../Layout/Text'

const Section = styled.section`
  width: 100%;
  min-height: 850px;
  background-image: url(${require('../../static/images/bg_conheca_taiyo.png')});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;

  ${media.lessThan('medium')`
    background: ${({ theme }) => theme.color.darkGrey};
  `}
`

const Box = styled.main`
  display: flex;
  background: ${({ theme }) => theme.color.darkGrey};
  margin-right: 10%;
  padding: 110px 63px;
  position: relative;
  max-width: 680px;
  flex-flow: row wrap;

  ${media.lessThan('medium')`
    max-width: 100%;
    margin-right: 0;
    padding: 60px 0;
  `}
`

const LeftHeadings = styled(Heading)`
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  position: absolute;
  top: -20px;
  left: 60px;

  ${media.lessThan('medium')`
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
  `}
`

const Description = styled(Text)`
  margin-top: 30px;
  text-align: left;
`

const About = props => (
  <Section ref={props.useRef}>
    <Box>
      <LeftHeadings>
        <Title>CONHEÇA</Title>
        <Subtitle>O Taiyo</Subtitle>
      </LeftHeadings>

      <Description>
        Oferecemos a autêntica culinária japonesa. Elaboramos todos os nossos pratos na hora e
        utilizamos somente ingredientes frescos.
      </Description>
      <Description>
        Nossa sequência começa com deliciosas e exóticas entradas elaboradas na hora pelos nossos
        experientes sushimans. Logo após, é servida uma barca composta em sua maioria por peixes e
        frutos do mar - peixes brancos, tradicional salmão, lula, atum, entre outros. Para finalizar
        essa experiência gastronômica são servidos deliciosos sushis doces com combinações inéditas
        e inimagináveis.
      </Description>
      <Description>
        Buscamos oferecer sempre a melhor experiência. Contamos com um ambiente aconchegante,
        aplicativo de reservas e programa de pontos, além de serviços de transfer com motorista em
        confortável carro esportivo de luxo.
      </Description>
    </Box>
  </Section>
)

export default About
