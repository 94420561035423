import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import FacebookIcon from '../../static/images/facebook.svg'
import InstagramIcon from '../../static/images/instagram.svg'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? '-25px' : '-100%')};
  left: 0;
  background-image: url(${require('../../static/images/bg_menu.svg')});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 195px;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);

  ${media.lessThan('medium')`
    height: 80vh;
    text-align: center;
  `}
`

const Nav = styled.nav`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
  padding: 0 140px;
  padding-right: 250px;
  max-width: 1980px;
  margin-top: 38px;

  ${media.lessThan('medium')`
    padding: 0;
  `}
`

const Item = styled.a`
  font-size: 18px;
  color: white;
  font-family: ${({ theme }) => theme.font.primary};
  text-transform: uppercase;
  margin: 0 20px;
  text-decoration: none;
  transition: all 200ms ease;
  :hover {
    opacity: 0.6;
  }

  ${media.lessThan('medium')`
    width: 100%;
    text-align: center;
    margin: 10px 0;

    :nth-child(6) {
      margin-left: auto;
      margin-right: 20px;
      margin-top: 50px;
    }

    :nth-child(7) {
      margin-left: 20px;
      margin-right: auto;
      margin-top: 50px;
    }


    :nth-child(6),
    :nth-child(7) {
      width: auto;
    }
  `}
`

const IconImage = styled.img`
  height: 20px;
`

const Menu = props => {
  return (
    <Wrapper isOpen={props.isOpen}>
      <Nav>
        <Item href="#/home">Home</Item>
        <Item href="#/o-taiyo">O Taiyo</Item>
        <Item href="#/cardapio">Cardápio</Item>
        <Item href="#/localizacao">Localização</Item>
        <Item href="#/contato">Contato</Item>
        <Item href="#/contato" target="_blank">
          <IconImage src={FacebookIcon} />
        </Item>
        <Item href="#/contato" target="_blank">
          <IconImage src={InstagramIcon} />
        </Item>
      </Nav>
    </Wrapper>
  )
}

export default Menu
