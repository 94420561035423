import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { useTransition, animated, config } from 'react-spring'

import { PlatesContext, PlatesProvider } from './context'
import { Heading, Title, Kanjis } from '../Layout/Headings'
import { Text } from '../Layout/Text'
import Marker from '../../static/images/cardapio.svg'
import Loading from '../Loading'

const Section = styled.section`
  padding: 160px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${media.lessThan('medium')`
    padding-bottom: 60px;
  `}
`

const Subtitle = styled(animated.strong)`
  font-family: 'Striverx';
  font-size: ${({ size }) => `${size || 91}px`};
  line-height: ${({ size }) => `${(size || 91) / 1.8}px`};
  font-weight: 100;
  display: block;
  color: white;
  position: relative;
  z-index: 1;

  ${media.lessThan('medium')`
    font-size: ${({ size }) => `${size * 0.8 || 91 * 0.8}px`};
    line-height: ${({ size }) => `${(size * 0.8 || 91 * 0.8) / 1.8}px`};
  `}
`

const Description = styled(Text)`
  max-width: 850px;
  margin-top: 165px;
`

const PlatesMenu = styled.ul`
  margin: 0 auto;
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 1860px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
`

const Item = styled.li`
  color: ${({ current, theme }) => (current ? 'white' : 'white')};
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 200ms ease;
  position: relative;

  span {
    position: relative;
    z-index: 2;
  }

  ${media.lessThan('medium')`
    width: 100%;
    text-align: center;
    margin: 10px 0;
  `}
`

const PlatesGrid = styled.div`
  width: 95%;
  max-width: 1860px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 100px;

  ${Subtitle} {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 78px;
  }
`

const Image = styled(animated.div)`
  width: 183px;
  height: 183px;
  background-color: grey;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;

  ${media.lessThan('medium')`
    width: 150px;
    height: 150px;
    margin: 0;
    margin-bottom: 15px;
  `}
`

const Plate = styled(animated.div)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  margin-bottom: 45px;

  ${media.lessThan('medium')`
    width: 100%;
    justify-content: center;
  `}
`

const PlateInfos = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 200px);
  padding-right: 20px;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const PlateTitle = styled.h2`
  color: white;
  font-size: 18px;
  line-height: 27px;
  width: 100%;

  ${media.lessThan('medium')`
    text-align: center;
    margin: 0;
  `}
`

const PlatePrice = styled(PlateTitle)`
  margin-top: 20px;
  width: 100%;
`

const PlateDescription = styled(Description)`
  margin: 0;
  font-size: 13px;
  text-align: left;

  ${media.lessThan('small')`
    width: 100%;
    text-align: center;
    margin: 20px 0;
  `}
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-image: url(${require(`../../static/images/bg_cardapio.png`)});
  background-position: center center;
  padding: 30px 0;
  margin-top: 140px;

  ${media.lessThan('medium')`
    margin-top: 20px;
    background: ${({ theme }) => theme.color.primary};
  `}
`

const Ifood = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 150px;

  ${media.lessThan('medium')`
    margin-top: 20px;
  `}

  ${Text} {
    font-size: 27px;
    line-height: 27px;
    font-weight: bold;
    color: white;
  }
`

const IfoodLogo = styled.img`
  margin-left: 20px;
`

const MarkerImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  transition: all 200ms ease;
  width: ${({ current }) => (current ? '120px' : '0')};

  ${media.lessThan('medium')`
    transform: translateX(-80%);
  `}
`

const LoadingScreen = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  align-item: center;
  justify-content: center;
`

const ImageCard = ({ imageSrc }) => {
  return <Image imageSrc={imageSrc} />
}

const Plates = props => {
  const { loading, categories, getCategories, category, setCategory } = useContext(PlatesContext)
  const plates = (category && category.plates) || []
  const title = (category && category.title) || ''

  const transition = useTransition(plates, item => item.id, {
    from: { transform: 'translate3d(0,40px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    order: ['leave', 'enter', 'update'],
    config: (item, state) => (state === 'leave' ? { duration: 200 } : config.default),
    trail: 0,
  })

  useEffect(() => {
    getCategories()
  }, [])

  const isCurrent = index => index === category.id

  if (loading)
    return (
      <LoadingScreen>
        <Loading />
      </LoadingScreen>
    )

  return (
    <Section ref={props.useRef}>
      <Heading>
        <Title>CULINÁRIA</Title>
        <Subtitle>Autêntica</Subtitle>
        <Kanjis src={require('../../static/images/kanjis_culinaria.png')} />
      </Heading>

      <Description>
        Os sabores incomparáveis da culinária japonesa do Taiyo são surpreendentes. O preparo de
        cada prato é minucioso, desde a maestria e precisão de seus cortes até a delicadeza e fusão
        de sabores das criações, tornando a experiência de degustação ainda mais saborosa. O
        festival de Sushis, Sashimis e pratos especiais tornam as opções do cardápio ainda mais
        irresistíveis.
      </Description>

      <Row>
        <PlatesMenu>
          {categories &&
            categories.length &&
            categories.map((category, index) => (
              <Item
                key={category.id}
                current={isCurrent(index)}
                onClick={e => setCategory(categories[index])}
              >
                <MarkerImage current={isCurrent(category.id)} src={Marker} />
                <span>{category.title}</span>
              </Item>
            ))}
        </PlatesMenu>
      </Row>

      {category && (
        <PlatesGrid>
          <Subtitle>{title}</Subtitle>

          {category.plates.length > 0 &&
            transition.map(({ item, props, key }) => (
              <Plate key={key} style={props}>
                <ImageCard imageSrc={item.image} />
                <PlateInfos>
                  <PlateTitle>{item.title}</PlateTitle>
                  <PlateDescription>{item.description}</PlateDescription>
                  <PlatePrice>R$ {item.price}</PlatePrice>
                </PlateInfos>
              </Plate>
            ))}
        </PlatesGrid>
      )}

      <Ifood>
        <Text>FAÇA SEU PEDIDO PELO </Text>
        <a href="https://www.ifood.com.br/delivery/caxias-do-sul-rs/taiyo-sushi-bar-santa-catarina/aa87392d-41f1-4f26-82f4-6bf03018f47b" target="_blank">
          <IfoodLogo src={require('../../static/images/ifood.png')} />
        </a>
      </Ifood>
    </Section>
  )
}

export default ({ ...props }) => (
  <PlatesProvider>
    <Plates {...props} />
  </PlatesProvider>
)
