import React, { Fragment, useRef, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from '../../configs/theme'
import { ParallaxProvider } from 'react-scroll-parallax'
import HashChange from 'react-hashchange'
import Header from '../Header'
import Home from '../Home'
import Experience from '../Experience'
import About from '../About'
import Plates from '../Plates'
import WorkingHour from '../WorkingHour'
import Location from '../Location'
import Contact from '../Contact'

const App = () => {
  const refs = {
    home: useRef(),
    'o-taiyo': useRef(),
    cardapio: useRef(),
    localizacao: useRef(),
    contato: useRef(),
  }
  const ExperienceRec = useRef()
  const WorkingHourRef = useRef()

  const [isMenuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const { hash } = window.location
      const id = hash.replace('!#/', '').replace('#/', '')

      if (refs[id]) {
        window.scrollTo({
          top: refs[id].current.offsetTop,
          behavior: 'smooth', // optional
        })
      }
    }, 1000)
  }, [])

  const onHashChange = hash => {
    const id = hash.hash.replace('!#/', '').replace('#/', '')

    if (refs[id]) {
      setMenuOpen(false)
      window.scrollTo({
        top: refs[id].current.offsetTop,
        behavior: 'smooth', // optional
      })
    }
  }

  return (
    <HashChange onChange={onHashChange}>
      {() => (
        <ThemeProvider theme={theme}>
          <Fragment>
            <ParallaxProvider>
              <GlobalStyle />
              <Header isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
              <Home useRef={refs.home} />
              <Experience useRef={ExperienceRec} />
              <About useRef={refs['o-taiyo']} />
              <Plates useRef={refs.cardapio} />
              <WorkingHour useRef={WorkingHourRef} />
              <Location useRef={refs.localizacao} />
              <Contact useRef={refs.contato} />
            </ParallaxProvider>
          </Fragment>
        </ThemeProvider>
      )}
    </HashChange>
  )
}

export default App
