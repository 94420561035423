import styled from 'styled-components'

export default styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
  pointer-events: none;
`
