import React from 'react'
import styled from 'styled-components'
import { Heading, Title, Subtitle, Kanjis } from '../Layout/Headings'
import { Text } from '../Layout/Text'

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 75px 0;
`

const Description = styled(Text)`
  margin-top: 98px;
`

const Experience = props => (
  <Section ref={props.useRef}>
    <Heading>
      <Title>EXPERIÊNCIA</Title>
      <Subtitle>Incrível</Subtitle>
      <Kanjis src={require('../../static/images/kanjis_experiencia_incrivel.png')} />
    </Heading>

    <Description>
      Se você busca viver uma experiência incrível apreciando a autêntica culinária japonesa com a
      garantia de pratos bem elaborados feitos na hora e com ingredientes frescos: venha e sinta-se
      em casa!
    </Description>
  </Section>
)

export default Experience
