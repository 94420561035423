import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid white;
  position: relative;
  margin-left: auto;
  position: relative;
  z-index: 333;
`

const Line = styled.div`
  width: 50%;
  height: 1px;
  border-radius: 3px;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scaleX(${({ isOpen }) => (isOpen ? 0 : 1)});
  transition: all 200ms ease;

  :first-of-type {
    top: ${({ isOpen }) => (isOpen ? 0 : '-11px')};
    transform: rotate(${({ isOpen }) => (isOpen ? '45deg' : 0)}) scaleX(1);
  }

  :last-of-type {
    bottom: ${({ isOpen }) => (isOpen ? 0 : '-10px')};
    transform: rotate(${({ isOpen }) => (isOpen ? '-45deg' : 0)}) scaleX(1);
  }
`

const Hamburger = ({ isOpen, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Line isOpen={isOpen} />
      <Line isOpen={isOpen} />
      <Line isOpen={isOpen} />
    </Wrapper>
  )
}

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default Hamburger
